import axios from "axios";
import { Controller } from "stimulus";

import * as app from "./../components/app";
import * as formHelpers from "./../utils/form-helpers";

export default class extends Controller {
  static targets = ["email"];

  initialize() {}

  connect() {
    document.addEventListener("keydown", (e) => {
      if (e.which === 13) this.requestMagicLink();
    });
  }

  disconnect() {}

  requestMagicLink() {
    if (formHelpers.isValidEmail(this.email)) {
      document.getElementById("loginButton").classList.add("loading");
      localStorage.clear();

      axios
        .get(`${app.config.hubPath}contacts/magiclink?gid=${app.config.group.groupId}&email=${this.email.toLowerCase()}`)
        .then((resp) => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          document.getElementById("loginForm").classList.add("hidden");
          document.getElementById("success").classList.remove("hidden");
        });
    } else {
      console.log("Invalid email...");
    }
  }

  get email() {
    return this.emailTarget.value;
  }
}
