"use strict";

export const getConfig = () => {
  let config = {};
  if (process.env.NODE_ENV === "production") {
    config = {
      hubPath: "https://webapp.kotive.com/hub/",
      apiv3Path: "https://webapp.kotive.com/apiv3/",
      bespokePath: "https://webapp.kotive.com/bespoke/",
      billingPath: "https://webapp.kotive.com/",
      refreshRate: 60 * 60,
      payments: [
        {
          provider: "payfast",
          action: "https://www.payfast.co.za/eng/process",
          method: "POST",
          merchant_id: "11072338",
          merchant_key: "o9i8siasdc4fl",
        },
      ],
    };
  } else {
    config = {
      hubPath: "http://127.0.0.1:9000/hub/",
      apiv3Path: "http://127.0.0.1:9000/apiv3/",
      bespokePath: "http://127.0.0.1:9000/bespoke/",
      billingPath: "https://f6eeb0cb.ngrok.io/",
      refreshRate: 1 * 60,
      payments: [
        {
          provider: "payfast",
          action: "https://sandbox.payfast.co.za/eng/process",
          method: "POST",
          merchant_id: "10014377",
          merchant_key: "yd5dy9r8t7z2b",
        },
      ],
    };
  }
  return config;
};
